import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { CartContext } from "../CartContext";
import { FaTrash } from "react-icons/fa";
import { getProductData } from "../firebase";

const CartProduct = ({ id, quantity, selectedSize, price }) => {
  const cart = useContext(CartContext);
  const [productData, setProductData] = useState(null);

  useEffect(() => {
    const fetchProductData = async () => {
      const data = await getProductData(id);
      setProductData(data);
    };

    fetchProductData();
  }, [id]);

  if (!productData) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <img className="image" src={productData.image} alt={productData.name} />
      <span>
        <h2>
          <span className="cartPSpan">
            <h4>{productData.brand}</h4>
            <h3>{productData.name}</h3>
          </span>
          <FaTrash
            onClick={() => cart.deleteFromCart(id)}
            className="deleteIcon"
          />
        </h2>
        <h4>{selectedSize}</h4>
        <div className="quantity">
          <span>
            <button onClick={() => cart.removeOneFromCart(id)}>-</button>
            <h1>{quantity}</h1>
            <button onClick={() => cart.addOneToCart(id, selectedSize)}>
              +
            </button>
          </span>
          <h3>€{(productData.price * quantity).toFixed(2)}</h3>
        </div>
      </span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  .image {
    height: 120px;
    width: auto;
    padding: 10px 0;
    object-fit: contain;
    border: 1px solid #eaeaea;
    border-radius: 20px;
  }
  span {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 1em;
    h2 {
      width: 100%;
      display: flex;
      span {
        display: flex;
      }
      .deleteIcon {
        color: #e69a9a;
        cursor: pointer;
      }
    }
    h4 {
      text-transform: capitalize;
      font-size: 17px;
      font-weight: 300;
      color: gray;
    }
    select {
      margin-bottom: 10px;
      padding: 5px;
    }
  }
`;

export default CartProduct;

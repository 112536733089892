import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";

const UserLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Login | Premium Perfume KS";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      await login(email, password);
      navigate("/account");
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
    }
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  return (
    <Container>
      <motion.h1
        ref={ref}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        Log In
      </motion.h1>
      {error && <p>{error}</p>}
      <motion.form
        onSubmit={handleSubmit}
        variants={{
          hidden: { opacity: 0, y: 100, scale: 0.6 },
          visible: { opacity: 1, y: 0, scale: 1 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          required
        />
        <button type="submit">Kyqu</button>
        <p>
          Nuk keni llogari të hapur?{" "}
          <Link className="link" to={"/signup"}>
            Regjistrohu
          </Link>
        </p>
      </motion.form>
    </Container>
  );
};

const Container = styled.div`
  padding: 5em 7%;
  text-align: center;
  margin-top: 2%;
  .error {
    color: red;
    padding: 1em;
  }
  h1 {
    font-family: "Comfortaa";
    font-size: 2.8em;
    margin-bottom: 10px;
  }
  form {
    margin: auto;
    background-color: #303030;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 40px 30px;
    width: 400px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    input {
      width: 100%;
      background: none;
      color: white;
      font-size: 18px;
      font-family: "Comfortaa";
      border: 1px solid white;
      border-radius: 20px;
      padding: 14px 14px;
      outline: none;
    }
    button {
      background: white;
      font-family: "Comfortaa", sans-serif;
      padding: 12px 20px;
      border: none;
      border-radius: 30px;
      font-size: 19px;
      margin-top: 5px;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: var(--primaryColor);
        color: white;
      }
    }
    p {
      margin-top: 1em;
      .link {
        color: white;
      }
    }
  }
  @media (max-width: 550px) {
    padding: 7em 5%;
    h1 {
      font-size: 8vw;
    }
    form {
      width: 100%;
    }
  }
`;

export default UserLogin;

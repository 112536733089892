import React, { useEffect, useState, useContext } from "react";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CartContext } from "../CartContext";
import { CiShoppingCart } from "react-icons/ci";
import { HiShoppingCart } from "react-icons/hi2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterCategory, setFilterCategory] = useState("all");
  const [filterPrice, setFilterPrice] = useState({ min: 0, max: 1000 });
  const [filterBrand, setFilterBrand] = useState("all");
  const [filterSeasons, setFilterSeasons] = useState([]);
  const cart = useContext(CartContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };
    fetchProducts();
  }, []);

  const handleAddToCart = (productId) => {
    cart.addOneToCart(productId);
    toast.success(t("added"), { autoClose: 1500 });
  };

  const handleRemoveFromCart = (productId) => {
    cart.removeOneFromCart(productId);
    toast.info("Product removed from cart!", { autoClose: 1500 });
  };

  const filteredProducts = products.filter((product) => {
    const matchesSearchQuery =
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.brand.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory =
      filterCategory === "all" ||
      product.category === filterCategory ||
      product.category === "Unisex";
    const matchesPrice =
      product.price >= filterPrice.min && product.price <= filterPrice.max;
    const matchesBrand = filterBrand === "all" || product.brand === filterBrand;
    const seasons = Array.isArray(product.seasons)
      ? product.seasons
      : [product.seasons];
    const matchesSeason =
      filterSeasons.length === 0 ||
      seasons.some((season) => filterSeasons.includes(season));

    return (
      matchesSearchQuery &&
      matchesCategory &&
      matchesPrice &&
      matchesBrand &&
      matchesSeason
    );
  });

  const uniqueBrands = [...new Set(products.map((product) => product.brand))];
  const uniqueSeasons = [
    ...new Set(
      products.flatMap((product) =>
        Array.isArray(product.seasons) ? product.seasons : [product.seasons]
      )
    ),
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Produktet | Beautyrocks Cosmetics KS";
  }, []);

  return (
    <Container>
      <ToastContainer />
      {/* New container to hold both search and filters in one row */}
      <SearchFilterRow>
        <SearchContainer>
          <input
            type="text"
            placeholder={t("search")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchContainer>

        <FilterContainer>
          <PriceRangeContainer>
            <div className="price-inputs">
              <input
                type="number"
                value={filterPrice.min}
                onChange={(e) =>
                  setFilterPrice({
                    ...filterPrice,
                    min: Number(e.target.value),
                  })
                }
                min={0}
                max={1000}
              />
              <span>-</span>
              <input
                type="number"
                value={filterPrice.max}
                onChange={(e) =>
                  setFilterPrice({
                    ...filterPrice,
                    max: Number(e.target.value),
                  })
                }
                min={0}
                max={1000}
              />
            </div>
          </PriceRangeContainer>

          <select
            value={filterSeasons}
            onChange={(e) => {
              const selectedOptions = Array.from(
                e.target.selectedOptions,
                (option) => option.value
              );
              if (selectedOptions.includes("")) {
                setFilterSeasons([]);
              } else {
                setFilterSeasons(selectedOptions);
              }
            }}
          >
            <option value="">{t("category")}</option>
            {uniqueSeasons.map((season) => (
              <option key={season} value={season}>
                {season}
              </option>
            ))}
          </select>
        </FilterContainer>
      </SearchFilterRow>

      <div className="items">
        {filteredProducts.map((product) => {
          const isInCart = cart.items.find((item) => item.id === product.id);

          return (
            <div className="item" key={product.id}>
              <Link to={`/produktet/${product.name}`} className="link-styles">
                <img src={product.image} alt={product.name} />
                <h3>{product.brand}</h3>
                <h2>{product.name}</h2>
              </Link>
              <span>
                <h1>€{product.price}</h1>
                {isInCart ? (
                  <HiShoppingCart
                    onClick={() => handleRemoveFromCart(product.id)}
                    className="icon"
                  />
                ) : (
                  <CiShoppingCart
                    onClick={() => handleAddToCart(product.id)}
                    className="icon"
                  />
                )}
                {/* <Link to={`/produktet/${product.name}`} className="link-styles"> */}
                {/* <FaRegEye className="icon eyeIcon" /> */}
                {/* </Link> */}
              </span>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

// Styles
const Container = styled.div`
  padding: 6em 7%;
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2em 0em;
    .item {
      margin-bottom: 2em;
      width: 260px;
      padding: 7px 16px;
      height: 300px;
      text-align: start;
      border-right: 1px solid #d3d3d3;
      border-left: 1px solid #d3d3d3;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #ddb41018;
      }
      img {
        width: 100%;
        height: 50%;
        object-fit: contain;
      }
      h3 {
        font-weight: 500;
        margin-top: 10px;
        color: var(--primaryColor);
      }
      h2 {
        font-family: "Comfortaa", sans-serif;
        font-size: 17px;
        text-transform: capitalize;
      }
      h1 {
        font-size: 23px;
        font-weight: 200;
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 7px;
        margin-top: 1em;
        .icon {
          padding: 7px;
          width: 47%;
          font-size: 2.3em;
          border-radius: 10px;
          background: var(--primaryColor);
          transition: 0.4s;
          cursor: pointer;
          color: white;
          &:hover {
            background: #837648;
          }
        }
        /* .eyeIcon {
          font-size: 3em;
          height: 40px;
          padding: 10px;
          width: 197%;
        } */
      }
      button {
        background: white;
        font-family: "Comfortaa", sans-serif;
        padding: 9px 20px 7px 20px;
        border: none;
        border-radius: 30px;
        font-size: 15px;
        margin-top: 1em;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          background: var(--primaryColor);
          color: white;
        }
      }
    }
  }
  @media (max-width: 625px) {
    padding: 4em 1%;
    .items {
      gap: 0;
      .item {
        width: 49%;
        text-align: start;
        h3 {
          font-size: 15px;
        }
        h2 {
          font-size: 16px;
        }
        h1 {
          font-size: 20px;
        }
      }
    }
  }
`;

const SearchFilterRow = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  align-items: center;
  margin-bottom: 2em;
  /* box-shadow: 3px 2px 12px 2px #dedede; */
  border-radius: 30px;
  padding: 2px 5px;
  @media (max-width: 1250px) {
    width: 100%;
  }
  @media (max-width: 950px) {
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1em;
    margin-top: 3em;
  }
`;

const SearchContainer = styled.div`
  width: 50%;
  input {
    width: 100%;
    font-family: "Comfortaa", sans-serif;
    padding: 12px 20px;
    font-size: 16px;
    box-shadow: 3px 2px 12px 2px #dedede;
    border-radius: 40px;
    border: 1px solid #e9e9e9;
    transition: 0.4s;
    &:hover,
    &:focus {
      background: white;
      color: black;
      box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
    }
  }
  @media (max-width: 700px) {
    width: 100%;
  }
`;

const FilterContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  gap: 2em;
  select {
    padding: 2px 20px;
    border-radius: 30px;
    box-shadow: 3px 2px 12px 2px #dedede;
    border: 1px solid white;
    font-family: "Comfortaa";
    outline: none;
    transition: 0.4s;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
  @media (max-width: 900px) {
    width: 100%;
  }
  @media (max-width: 450px) {
    width: 100%;
    gap: 10px;
    select {
      padding: 2px 12px;
    }
  }
`;

const PriceRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .price-inputs {
    display: flex;
    align-items: center;
    gap: 10px;
    input {
      width: 100px;
      padding: 8px;
      border-radius: 17px;
      border: 1px solid #ddd;
      text-align: center;
      box-shadow: 3px 2px 12px 2px #dedede;
      outline: none;
    }
  }
  @media (max-width: 450px) {
    .price-inputs {
      gap: 7px;
      input {
        width: 80px;
        padding: 10px 12px;
      }
    }
  }
`;

export default Products;

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAuth } from "../context/AuthContext";
import ProductsTable from "../components/ProductsTable";
import AdminDashboard from "../routes/AdminDashboard";
// import Orders from "../components/Orders";

const AdminPage = () => {
  const { logout, currentUser } = useAuth();
  const [popUp, setPopup] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Admin | Beautyrocks Cosmetics";
  }, []);
  return (
    <Container>
      {currentUser && currentUser.email && (
        <>
          <h2 className="acc">
            Jeni kyqur si admin: <span>{currentUser.email}</span>!
          </h2>
        </>
      )}
      <button className="accBtn" onClick={() => setPopup(true)}>
        Log Out
      </button>
      {popUp === true ? (
        <div className="popup">
          <p>Are You Sure You Wanna Log Out?</p>
          <div className="btns">
            <button onClick={logout}>Yes</button>
            <button onClick={() => setPopup(false)}>No</button>
          </div>
        </div>
      ) : (
        ""
      )}
      <AdminDashboard />
      <ProductsTable />
      {/* <Orders /> */}
    </Container>
  );
};
const Container = styled.div`
  padding: 7em 7%;
  .popup {
    text-align: center;
    margin: auto;
    position: fixed;
    width: 400px;
    box-shadow: 0 0 13px 2px #1d1d1d;
    left: 35%;
    padding: 30px;
    background: #2e2e2e;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .btns {
      display: flex;
      gap: 7px;
      button {
        margin-top: 1em;
        font-size: 17px;
        background: #e48888;
        color: var(--primaryBackgroundColor);
        padding: 10px 17px;
        border: none;
        cursor: pointer;
        transition: 0.4s;
        :hover {
          box-shadow: 0 0 10px 3px var(--shadowColor);
        }
        :nth-child(2) {
          background-color: #92f092;
        }
      }
    }
  }
  .acc {
    text-align: center;
    margin-top: 2em;
    font-weight: 400;
    color: #999999;
    span {
      color: var(--fontPrimaryColor);
    }
  }
  .accBtn {
    display: flex;
    margin: auto;
    margin-top: 1em;
    font-size: 17px;
    background: #e48888;
    color: var(--primaryBackgroundColor);
    padding: 14px 17px;
    border: none;
    cursor: pointer;
    transition: 0.4s;
    :hover {
      box-shadow: 0 0 10px 3px var(--shadowColor);
    }
  }
`;

export default AdminPage;

import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";

gsap.registerPlugin(ScrollTrigger);

const Text = () => {
  const wordsRef = useRef([]);

  useEffect(() => {
    const words = wordsRef.current;

    // Entry animation for each word
    gsap.fromTo(
      words,
      { opacity: 0, x: 100, rotation: 10, scale: 0.8 },
      {
        opacity: 1,
        x: 0,
        rotation: 0,
        scale: 1,
        stagger: 0.4, // Slower stagger between words
        ease: "power4.out",
        scrollTrigger: {
          trigger: ".hero-section",
          start: "top top",
          end: "+=500",
          scrub: 2, // Slower scroll-based animation
        },
      }
    );

    // Horizontal scrolling effect without overlap
    gsap.to(".big-text", {
      x: () =>
        -document.querySelector(".big-text").scrollWidth + window.innerWidth,
      ease: "none",
      scrollTrigger: {
        trigger: ".hero-section",
        start: "top top",
        scrub: 3, // Slower scroll for horizontal text movement
        pin: true,
        end: "+=1500", // Increase the scroll distance for a slower effect
      },
    });
  }, []);

  return (
    <HeroSection className="hero-section">
      <TextContainer className="text-container">
        <BigText className="big-text">
          {["Beauty", "& ", "Cosmetics", "high", "quality", "products."].map(
            (word, index) => (
              <Word key={index} ref={(el) => (wordsRef.current[index] = el)}>
                {word}
              </Word>
            )
          )}
        </BigText>
      </TextContainer>
    </HeroSection>
  );
};

// Styled Components
const HeroSection = styled.div`
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
`;

const BigText = styled.h1`
  font-size: 8vw;
  display: flex;
  white-space: nowrap;
  text-transform: uppercase;
  gap: 20px;
  padding-left: 15%;

  @media (max-width: 768px) {
    font-size: 3rem;
  }
  @media (max-width: 508px) {
    font-size: 6rem;
  }
`;

const Word = styled.span`
  opacity: 0;
  display: inline-block;
  padding: 0 20px;
  color: #2f2f2f;
  transition: color 0.3s ease;

  &:hover {
    color: var(--primaryColor);
  }
`;

export default Text;

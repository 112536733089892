import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CartContext } from "../CartContext";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { motion, useAnimation, useInView } from "framer-motion";
import { BsCashCoin } from "react-icons/bs";
import { IoIosCard } from "react-icons/io";
import { db } from "../firebase";
import { collection, addDoc } from "firebase/firestore";

const CheckoutPage = ({ totalPrice }) => {
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const [shippingPrice, setShippingPrice] = useState(2);
  const [selectedCountry, setSelectedCountry] = useState("Kosove");
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = "Checkout | Beautyrocks Cosmetics KS";
  }, []);

  const productsCount = cart.items.reduce(
    (sum, product) => sum + product.quantity,
    0
  );
  const subtotal = cart.items.reduce(
    (sum, product) => sum + product.quantity * product.price,
    0
  );

  useEffect(() => {
    // Set shipping price based on selected country
    if (selectedCountry === "Kosove") {
      setShippingPrice(subtotal > 344435 ? 0 : 2);
    } else {
      setShippingPrice(5); // Shipping price for Shqiperia and Macedonia
    }
  }, [subtotal, selectedCountry]);

  const sendEmail = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target); // Collect form data
    const userDetails = {
      name: formData.get("name"),
      phoneNumber: formData.get("phoneNumber"),
      email: formData.get("email"),
      country: formData.get("country"),
      city: formData.get("city"),
      address: formData.get("address"),
      message: formData.get("message") || "", // Optional message
    };

    const productDetails = cart.items
      .map(
        (product) =>
          `Produkti: ${product.brand} ${product.name}, Sasia: ${product.quantity}, Cmimi: €${product.price}`
      )
      .join("\n");

    const orderDetails = {
      ...userDetails,
      productDetails: productDetails,
      totalPrice: (subtotal + shippingPrice).toFixed(2),
      timestamp: new Date(),
    };

    try {
      // Send email with EmailJS
      await emailjs.send(
        "service_d2qd19g", // Your EmailJS service ID
        "template_376yutt", // Your EmailJS template ID
        {
          ...orderDetails, // Spread the order details to populate the template
        },
        "oMZ_Wg0HM2n39vbS6" // Your EmailJS user ID
      );

      navigate("/success-page");
      toast.success("Porosia u bë me sukses!");

      // Save order details in Firestore
      const docRef = await addDoc(collection(db, "orders"), orderDetails);
      console.log("Order registered with ID: ", docRef.id);
    } catch (err) {
      console.log(err);
      toast.error("Dicka shkoi keq, provoni perseri.");
    }
  };

  const handleShippingChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedCountry(selectedOption);
  };

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
  }, [isInView]);

  const handleNextStep = (e) => {
    e.preventDefault();
    const form = e.target;
    if (!form.checkValidity()) {
      form.reportValidity();
      return;
    }
    const formData = new FormData(e.target);
    const details = {
      name: formData.get("name"),
      phoneNumber: formData.get("phoneNumber"),
      email: formData.get("email"),
      country: formData.get("country"),
      city: formData.get("city"),
      address: formData.get("address"),
      message: formData.get("message") || "",
    };
    // setUserDetails(details);
    // setCurrentStep(2);
  };

  return (
    <Container>
      <ToastContainer />
      <motion.div
        ref={ref}
        className="left"
        variants={{
          hidden: { opacity: 0, x: -300 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        {cart.items.map((product) => (
          <div className="item" key={product.id}>
            <img src={product.image} alt="" />
            <span>
              <h4>{product.brand}</h4>
              <h3>{product.name}</h3>
              {/* <h4>
                Madhësia: <span>{product.size}</span>
              </h4> */}
              <h4>
                Sasia: <span>{product.quantity} copë</span>
              </h4>
              <h2>€{(product.quantity * product.price).toFixed(2)}</h2>
            </span>
          </div>
        ))}
        <div className="total">
          <span>
            <h3>Subtotal</h3>
            <h2>€{subtotal.toFixed(2)}</h2>
          </span>
          <span>
            <h3>Transporti</h3>
            {shippingPrice === 0 ? (
              <h2>FALAS</h2>
            ) : (
              <h2>€{shippingPrice.toFixed(2)}</h2>
            )}
          </span>
          <hr />
          <span>
            <h2>Totali</h2>
            <h1>€{(subtotal + shippingPrice).toFixed(2)}</h1>
          </span>
        </div>
      </motion.div>
      <hr />
      <motion.div
        className="right"
        variants={{
          hidden: { opacity: 0, x: 300 },
          visible: { opacity: 1, x: 0 },
        }}
        initial="hidden"
        animate={animation}
        transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
      >
        {/* {currentStep === 1 ? ( */}
        <form onSubmit={sendEmail}>
          <input
            type="hidden"
            name="productDetails"
            value={cart.items
              .map(
                (product) =>
                  `${product.brand} - ${product.name} - Quantity: ${
                    product.quantity
                  } - Cmimi Total: €${
                    product.price
                  } - Transporti ${shippingPrice.toFixed(2)} - Total: €${(
                    subtotal + shippingPrice
                  ).toFixed(2)}`
              )
              .join("\n")}
          />
          <div className="contact">
            <p>
              <b>Note:</b> Pagesa behet pasi te pranohet porosia
            </p>
            <label>Detajet e porosisë</label>
            <input
              name="name"
              type="text"
              placeholder="Emri dhe Mbiemri"
              required
            />
            <span>
              <input
                type="phone"
                name="phoneNumber"
                placeholder="Numri i telefonit"
                required
              />
              <select
                onChange={(e) => {
                  handleShippingChange(e);
                  setSelectedCountry(e.target.value);
                }}
                name="country"
                required
              >
                <option value="Kosove">Kosove</option>
                <option value="Shqiperi">Shqiperi</option>
                <option value="Macedoni">Macedoni</option>
              </select>
            </span>
            <span>
              <input name="city" type="text" placeholder="Qyteti" required />
              <input
                name="address"
                type="text"
                placeholder="Addresa*"
                required
              />
            </span>
            <textarea
              name="message"
              cols="10"
              rows="4"
              placeholder="Mesazh (Opsionale)"
            ></textarea>
            <button type="submit">POROSIT TANI</button>
          </div>
        </form>
        {/* ) : ( */}
        {/* <form onSubmit={sendEmail}>
          <div className="paymentMethods">
            <span>
              <IoIosCard className="icon" />
              <p>Paguaj online</p>
            </span>
            <p>ose</p>
            <span>
              <BsCashCoin className="icon" />
              <p>Paguaj me para në dorë</p>
            </span>
          </div>
          <div className="cash">
            <button type="submit">Paguaj Cash</button>
          </div>
        </form> */}
        {/* )} */}
      </motion.div>
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7%;
  margin: auto;
  max-width: 1500px;
  hr {
    height: 70vh;
    border: 1px solid #7d6d374e;
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: end;
    flex: 3;
    .item {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      align-self: flex-start;
      border-bottom: 1px solid #c9c9c9;
      width: 100%;
      img {
        height: 80px;
        width: 100px;
        object-fit: contain;
      }
      span {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        h4 {
          text-transform: capitalize;
          font-size: 15px;
          font-weight: 300;
          color: var(--primaryColor);
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 7px;
          span {
            color: #3a3a3a;
            font-weight: 400;
            font-size: 15px;
          }
        }
        h3 {
          font-weight: 400;
          color: #3a3a3a;
          font-size: 17px;
        }
        h2 {
          margin-top: 4px;
          font-weight: 500;
          font-size: 18px;
          color: #4f4f4f;
        }
      }
    }
    .total {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      margin-top: 3em;
      hr {
        border: none;
        height: 1px;
        border-bottom: 1px solid #bbbbbb;
      }
      span {
        display: flex;
        justify-content: space-between;
        h3 {
          font-weight: 500;
          color: #454545;
        }
        h2 {
          font-weight: 500;
          font-size: 19px;
          color: #838383;
        }
        h1 {
          font-size: 20px;
          font-weight: 600;
          color: #434343;
        }
      }
    }
  }
  .right {
    flex: 4;
    display: flex;
    flex-direction: column;
    gap: 2em;
    .paymentMethods {
      margin-top: 1em;
      align-self: start;
      display: flex;
      flex-direction: row;
      justify-content: center;
      justify-self: start;
      flex-wrap: wrap;
      gap: 1em;
      p {
        color: gray;
      }
      span {
        display: flex;
        align-items: center;
        gap: 7px;
        p {
          font-size: 15px;
          color: #4d4d4d;
        }
      }
    }
    .cash {
      margin: 2em 0;
    }
    p {
      padding: 10px 0;
      color: gray;
    }
    .card {
      margin-top: 2em;
    }
    div {
      display: flex;
      flex-direction: column;
      gap: 7px;
    }
    span {
      font-weight: 400;
      display: flex;
      align-items: center;
      gap: 7px;
    }
    input,
    textarea,
    select {
      font-family: "Montserrat", sans-serif;
      outline: none;
      border: 1px solid #c2c2c2;
      font-size: 17px;
      border-radius: 7px;
      padding: 14px;
      background: none;
      color: gray;
      width: 100%;
    }
    label {
      font-weight: 500;
      font-size: 20px;
    }
    .shipping {
      background-color: var(--secondaryBackgroundColor);
      padding: 20px 30px;
      border-radius: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      span {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        h3 {
          font-size: 20px;
          font-weight: 500;
        }
        h4 {
          font-weight: 300;
        }
      }
    }
    .payBtn {
      margin: 1em 0;
      font-size: 20px;
      padding: 13px 0;
    }
    .paypalBtns {
      display: flex;
    }
    button {
      margin: auto;
      margin-top: 10px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      background: var(--primaryColor);
      color: white;
      border: none;
      padding: 15px 14px;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: #7d6d37;
      }
    }
  }
  @media (max-width: 700px) {
    flex-direction: column;
    gap: 2em;
    hr {
      height: 1px;
    }
  }
  @media (max-width: 450px) {
    padding: 7em 4%;
    .right {
      .paymentMethods {
        align-items: center;
        width: 100%;
        flex-direction: column;
      }
    }
  }
`;

export default CheckoutPage;

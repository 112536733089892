import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import Navbar from "./components/Navbar";
import ProductsTable from "./components/ProductsTable";
import UserLogin from "./routes/UserLogin";
import UserSignup from "./routes/UserSignUp";
import { AuthProvider } from "./context/AuthContext";
import CartProvider from "./CartContext";
import AdminRoute from "./routes/AdminRoute";
import AdminDashboard from "./routes/AdminDashboard";
import Products from "./routes/Products";
import ProductPage from "./routes/ProductPage";
import AdminPage from "./routes/AdminPage";
import CheckoutPage from "./routes/CheckoutPage";
import Footer from "./components/Footer";
import Contact from "./routes/Contact";
import SuccessPage from "./routes/SuccessPage";

function App() {
  return (
    <AuthProvider>
      <CartProvider>
        <div className="App">
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/produktet" element={<Products />} />
            <Route path="/kontakti" element={<Contact />} />
            <Route path="/produktet/:name" element={<ProductPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
            <Route path="/success-page" element={<SuccessPage />} />
            <Route
              path="/admin/products"
              element={
                <AdminRoute>
                  <ProductsTable />
                </AdminRoute>
              }
            />
            <Route
              path="/admin/add-product"
              element={
                <AdminRoute>
                  <AdminDashboard />
                </AdminRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <AdminPage />
                </AdminRoute>
              }
            />
            <Route path="/login" element={<UserLogin />} />
            <Route path="/signup" element={<UserSignup />} />
          </Routes>
          <Footer />
        </div>
      </CartProvider>
    </AuthProvider>
  );
}

export default App;

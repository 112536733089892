import React, { useEffect } from "react";
import styled from "styled-components";
import Banner from "../components/homeComponents/Banner";
import Banner2 from "../components/homeComponents/Banner2";
import Text from "../components/homeComponents/Text";
import BestSellers from "../components/homeComponents/BestSellers";
import Text2 from "../components/homeComponents/Text2";
import PerfumesSlider from "../components/homeComponents/PerfumesSlider";
import SkincareSlider from "../components/homeComponents/SkincareSlider";
import MakeUpSlider from "../components/homeComponents/MakeUpSlider";
import Words from "../components/homeComponents/Words";
import FAQSection from "../components/homeComponents/FAQSection";
import Contact from "./Contact";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title =
      "Beautyrocks Cosmetics - Beauty & Cosmetics high-quality products";
  }, []);
  return (
    <Container>
      <Banner />
      <Text />
      <BestSellers />
      <Text2 />
      <PerfumesSlider />
      <SkincareSlider />
      <MakeUpSlider />
      <Words />
      <FAQSection />
      <Contact />
    </Container>
  );
};
const Container = styled.div`
  padding: 0;
`;

export default Home;

// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);

// Function to get product data
export const getProductData = async (id) => {
  try {
    const docRef = doc(db, "products", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching product data:", error);
    return null;
  }
};
export const saveCartToFirebase = async (userId, cartItems) => {
  const cartRef = doc(db, "carts", userId);
  await setDoc(cartRef, { items: cartItems });
};

// Function to get the cart from Firebase
export const getCartFromFirebase = async (userId) => {
  const cartRef = doc(db, "carts", userId);
  const cartDoc = await getDoc(cartRef);
  if (cartDoc.exists()) {
    return cartDoc.data().items;
  } else {
    return [];
  }
};

// Function to update the cart in Firebase
export const updateCartInFirebase = async (userId, cartItems) => {
  const cartRef = doc(db, "carts", userId);
  await updateDoc(cartRef, { items: cartItems });
};

// Function to delete the cart from Firebase
export const deleteCartFromFirebase = async (userId) => {
  const cartRef = doc(db, "carts", userId);
  await deleteDoc(cartRef);
};

export default app;

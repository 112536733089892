import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import styled from "styled-components";

const ProductsTable = () => {
  const [products, setProducts] = useState([]);
  const [editingProductId, setEditingProductId] = useState(null);
  const [editProduct, setEditProduct] = useState({
    name: "",
    brand: "",
    description: "",
    price: "",
    image: "",
    category: "",
  });

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    };
    fetchProducts();
  }, []);

  const handleEditClick = (product) => {
    setEditingProductId(product.id);
    setEditProduct(product);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditProduct({ ...editProduct, [name]: value });
  };

  const handleSaveClick = async () => {
    const productDoc = doc(db, "products", editingProductId);
    await updateDoc(productDoc, editProduct);
    setEditingProductId(null);
    setProducts(
      products.map((product) =>
        product.id === editingProductId ? editProduct : product
      )
    );
  };

  const handleDeleteClick = async (id) => {
    await deleteDoc(doc(db, "products", id));
    setProducts(products.filter((product) => product.id !== id));
  };

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <th>Image</th>
            {/* <th>Brand</th> */}
            <th>Name</th>
            <th>Description</th>
            <th>Price</th>
            {/* <th>Category</th> */}
            <th>Seasons</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {products.map((product) => (
            <tr key={product.id}>
              {editingProductId === product.id ? (
                <>
                  <td>
                    <input
                      type="text"
                      name="image"
                      value={editProduct.image}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="name"
                      value={editProduct.name}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="description"
                      value={editProduct.description}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      name="price"
                      value={editProduct.price}
                      onChange={handleInputChange}
                    />
                  </td>

                  {/* <td>
                    <input
                      type="text"
                      name="category"
                      value={editProduct.category}
                      onChange={handleInputChange}
                    />
                  </td> */}
                  <td>
                    {editingProductId === product.id ? (
                      <input
                        type="text"
                        value={editProduct.seasons}
                        onChange={(e) =>
                          setEditProduct({
                            ...editProduct,
                            seasons: e.target.value,
                          })
                        }
                      />
                    ) : Array.isArray(product.seasons) ? (
                      product.seasons.join(", ")
                    ) : (
                      product.seasons
                    )}
                  </td>
                  <td>
                    <button onClick={handleSaveClick} className="saveBtn">
                      Save
                    </button>
                    <button
                      onClick={() => handleDeleteClick(product.id)}
                      className="deleteBtn"
                    >
                      Delete
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td>
                    {product.image ? (
                      <img
                        src={product.image}
                        alt={product.name}
                        className="product-image"
                      />
                    ) : (
                      "No image"
                    )}
                  </td>
                  {/* <td>{product.brand}</td> */}
                  <td>{product.name}</td>
                  <td>{product.description}</td>
                  <td>{product.price}</td>
                  {/* <td>{product.category}</td> */}
                  <td>{product.seasons}</td>
                  <td>
                    <button onClick={() => handleEditClick(product)}>
                      Edit
                    </button>
                    <button
                      className="deleteBtn"
                      onClick={() => handleDeleteClick(product.id)}
                    >
                      Delete
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  margin: 20px;
  overflow-x: auto;
  width: 100%;
  margin-top: 5em;
  overflow-x: scroll;

  .product-image {
    height: 60px;
    object-fit: contain;
  }

  @media (max-width: 768px) {
    .product-image {
      height: 40px;
    }
    table {
      display: block;
      width: 100%;
      overflow-x: auto;
      white-space: nowrap;
    }
    thead {
      display: none;
    }
    tbody {
      display: block;
    }
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
    }
    td {
      display: block;
      text-align: right;
      padding: 10px;
      border: none;
      position: relative;
      .saveBtn {
        background: green;
      }
      .deleteBtn {
        background: red;
      }
      ::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding: 0 10px;
        font-weight: bold;
        white-space: nowrap;
        text-align: left;
      }
    }
  }
`;

const Table = styled.table`
  width: 98%;
  border-collapse: collapse;

  th {
    background-color: #333333; /* Dark gray color */
    color: white;
    padding: 10px;
    text-align: left;
    border-radius: 10px 10px 0 0;
  }

  td {
    padding: 10px;
    border: 1px solid #ddd;
    .deleteBtn {
      background: #d87474;
    }
  }

  button {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    margin-right: 5px;
  }

  input {
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%;
  }

  img {
    max-width: 100px;
    height: auto;
  }
`;

export default ProductsTable;

import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IoMdGlobe } from "react-icons/io";
import alFlag from "../assets/homeAssets/albFlag.webp";
import enFlag from "../assets/homeAssets/engFlag.png";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [showFlags, setShowFlags] = useState(false);

  const toggleFlags = () => {
    setShowFlags(!showFlags);
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setShowFlags(false); // Optionally close the flags after selecting one
  };

  const languageFlags = {
    en: enFlag,
    sq: alFlag,
  };

  return (
    <Container>
      <GlobeIcon onClick={toggleFlags}>
        <IoMdGlobe size={33} />
      </GlobeIcon>
      {showFlags && (
        <FlagsContainer>
          {Object.keys(languageFlags).map((language) => (
            <img
              key={language}
              src={languageFlags[language]}
              alt={language}
              className={`flag-icon ${
                language === i18n.language ? "active" : ""
              }`}
              onClick={() => changeLanguage(language)}
            />
          ))}
        </FlagsContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const GlobeIcon = styled.div`
  cursor: pointer;
  margin-top: 5px;
  &:hover {
    color: var(--primaryColor);
  }
`;

const FlagsContainer = styled.div`
  display: flex;
  gap: 4px;
  position: absolute;
  height: 40px;
  top: 100%;
  left: 0;
  padding: 4px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  img {
    width: 40px;
    max-height: 30px;
    cursor: pointer;
    border: 2px solid transparent;
    &.active {
      border: 2px solid blue;
    }
  }
`;

export default LanguageSelector;

import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  collection,
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "./firebase";
import { AuthProvider } from "./context/AuthContext";

export const CartContext = createContext({
  items: [],
  wishlist: [],
  getProductQuantity: () => {},
  addOneToCart: () => {},
  removeOneFromCart: () => {},
  deleteFromCart: () => {},
  getTotalCost: () => {},
  addToWishlist: () => {},
  removeFromWishlist: () => {},
});

export function CartProvider({ children }) {
  const [cartProducts, setCartProducts] = useState([]);
  const [wishlist, setWishlist] = useState([]);
  const { currentUser } = useContext(AuthProvider) || { currentUser: null };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart"));
    if (storedCart) {
      setCartProducts(storedCart);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cartProducts));
  }, [cartProducts]);

  useEffect(() => {
    if (currentUser) {
      const fetchWishlist = async () => {
        try {
          const docRef = doc(db, "wishlists", currentUser.uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            setWishlist(docSnap.data().items || []);
          }
        } catch (error) {
          console.error("Error fetching wishlist: ", error);
        }
      };
      fetchWishlist();
    }
  }, [currentUser]);

  const getProductData = useCallback(async (id) => {
    try {
      const productRef = doc(collection(db, "products"), id);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        return { id: productSnap.id, ...productSnap.data() };
      } else {
        console.log("No such product!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching product: ", error);
      return null;
    }
  }, []);

  const getProductQuantity = useCallback(
    (id) => {
      const product = cartProducts.find((product) => product.id === id);
      return product ? product.quantity : 0;
    },
    [cartProducts]
  );

  const addOneToCart = useCallback(
    async (id) => {
      const existingProduct = cartProducts.find((product) => product.id === id);

      if (!existingProduct) {
        const productToAdd = await getProductData(id); // Fetch the product from Firebase
        if (productToAdd) {
          const price = productToAdd.price || 0; // Fallback to 0 if no price is available
          setCartProducts((prev) => [
            ...prev,
            { ...productToAdd, quantity: 1, price },
          ]);
        }
      } else {
        setCartProducts((prev) =>
          prev.map((product) =>
            product.id === id
              ? { ...product, quantity: product.quantity + 1 }
              : product
          )
        );
      }
    },
    [cartProducts, getProductData]
  );

  const removeOneFromCart = useCallback(
    (id) => {
      const quantity = getProductQuantity(id);

      if (quantity === 1) {
        deleteFromCart(id);
      } else {
        setCartProducts((prev) =>
          prev.map((product) =>
            product.id === id
              ? { ...product, quantity: product.quantity - 1 }
              : product
          )
        );
      }
    },
    [cartProducts, getProductQuantity]
  );

  const getProductPrice = useCallback((size) => {
    switch (size) {
      case "25ml":
        return 9.99;
      case "50ml":
        return 14.99;
      case "100ml":
        return 24.99;
      default:
        return 9.99;
    }
  }, []);

  const deleteFromCart = useCallback((id) => {
    setCartProducts((prev) => prev.filter((product) => product.id !== id));
  }, []);

  const getTotalCost = useCallback(() => {
    return cartProducts.reduce(
      (sum, cartItem) => sum + (cartItem.price || 0) * cartItem.quantity,
      0
    );
  }, [cartProducts]);

  const addToWishlist = useCallback(
    async (product) => {
      if (!currentUser) return;

      try {
        const docRef = doc(db, "wishlists", currentUser.uid);
        await updateDoc(docRef, {
          items: arrayUnion(product),
        });
        setWishlist((prev) => [...prev, product]);
      } catch (error) {
        console.error("Error adding to wishlist: ", error);
      }
    },
    [currentUser]
  );

  const removeFromWishlist = useCallback(
    async (productId) => {
      if (!currentUser) return;

      try {
        const docRef = doc(db, "wishlists", currentUser.uid);
        const productToRemove = wishlist.find((item) => item.id === productId);
        if (productToRemove) {
          await updateDoc(docRef, {
            items: arrayRemove(productToRemove),
          });
          setWishlist((prev) => prev.filter((item) => item.id !== productId));
        }
      } catch (error) {
        console.error("Error removing from wishlist: ", error);
      }
    },
    [currentUser, wishlist]
  );

  const contextValue = {
    items: cartProducts,
    wishlist,
    getProductQuantity,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getTotalCost,
    addToWishlist,
    removeFromWishlist,
    getProductData,
  };

  return (
    <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>
  );
}

export default CartProvider;

import React from "react";
import styled from "styled-components";
import img1 from "../../assets/homeAssets/ps1.png";
import img2 from "../../assets/homeAssets/ps2.png";
import img3 from "../../assets/homeAssets/ps3.png";
import img4 from "../../assets/homeAssets/ps4.png";
import img5 from "../../assets/homeAssets/ps1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PerfumesSlider = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h1 className="bgText">BEAUTYROCKS</h1>
      <title>
        <h1>{t("perfumes")}</h1>
      </title>
      <Swiper
        className="swiper leftSwiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={40}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="slide">
          <img src={img1} alt="" />
          <span>
            <h3>EP154 Extrait de Parfum Intense 100ml</h3>
            <div>
              <h4>29.99€</h4>
              <Link
                to={
                  "/produktet/EP154%20Extrait%20de%20Parfum%20Intense%20100ml"
                }
                className="link-styles"
              >
                <button>{t("seeMore")}</button>
              </Link>
            </div>
          </span>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={img2} alt="" />
          <span>
            <h3>EP017 Extrait de Parfum Intense 100ml</h3>
            <div>
              <h4>29.99€</h4>
              <Link
                to={
                  "/produktet/EP017%20Extrait%20de%20Parfum%20Intense%20100ml"
                }
                className="link-styles"
              >
                <button>{t("seeMore")}</button>
              </Link>
            </div>
          </span>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={img3} alt="" />
          <span>
            <h3>EP083 Extrait de Parfum Intense 100ml</h3>
            <div>
              <h4>29.99€</h4>
              <Link
                to={
                  "/produktet/EP083%20Extrait%20de%20Parfum%20Intense%20100ml"
                }
                className="link-styles"
              >
                <button>{t("seeMore")}</button>
              </Link>
            </div>
          </span>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={img4} alt="" />
          <span>
            <h3>EP006 Extrait de Parfum Intense 100ml</h3>
            <div>
              <h4>29.99€</h4>
              <Link
                to={
                  "/produktet/EP006%20Extrait%20de%20Parfum%20Intense%20100ml"
                }
                className="link-styles"
              >
                <button>{t("seeMore")}</button>
              </Link>
            </div>
          </span>
        </SwiperSlide>
        <SwiperSlide className="slide">
          <img src={img2} alt="" />
          <span>
            <h3>PD251 Extrait de Parfum 50ml</h3>
            <div>
              <h4>19.19€</h4>
              <Link
                to={"//produktet/PD251%20Extrait%20de%20Parfum%2050ml"}
                className="link-styles"
              >
                <button>{t("seeMore")}</button>
              </Link>
            </div>
          </span>
        </SwiperSlide>
      </Swiper>
      <Link to={"/produktet"} className="link-styles">
        <p className="seeAll">{t("seeMore")} </p>
      </Link>
    </Container>
  );
};
const Container = styled.div`
  padding: 4em 0%;
  .bgText {
    position: absolute;
    font-size: 12vw;
    width: 100%;
    text-align: center;
    z-index: 0;
    opacity: 0.05;
  }
  title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    text-align: center;
    z-index: 2;
    h1 {
      color: #d8ac64;
      font-family: "Alta";
      font-size: 7vw;
      font-weight: 100;
      z-index: 2;
    }
    p {
      margin-right: 0;
      color: gray;
      z-index: 2;
    }
  }
  .swiper {
    margin: 1em 7%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3em 2%;
    padding: 1em 2em 4em 2em;
    border-radius: 30px;
    z-index: 2;
    .slide {
      width: 360px;
      height: 400px;
      text-align: center;
      border-radius: 20px 20px;
      z-index: 2;
      img {
        padding: 1px 1px 0 1px;
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 20px 20px 0 0;
      }
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 14px 10px;
        border-left: 1px solid #b7aca2;
        border-right: 1px solid #b7aca2;
        h3 {
          color: #807973;
          font-weight: 500;
        }
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 12px 20px 3px 20px;
          h4 {
            color: #61584c;
            font-size: 19px;
          }
          .icon {
            color: #61584c;
            font-size: 30px;
          }
          button {
            background: var(--primaryColor);
            color: white;
            border: none;
            font-family: "Montserrat", sans-serif;
            font-size: 18px;
            padding: 8px 14px;
            cursor: pointer;
            transition: 0.4s;
            &:hover {
              box-shadow: 0 0 10px 1px #b1aaa2;
            }
          }
        }
      }
    }
  }
  .swiper-pagination {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #554f4f; /* Change to your desired active color */
    opacity: 1;
  }
  .seeAll {
    text-align: end;
    margin-right: 7%;
    margin-top: 0.4em;
  }
  @media (max-width: 1300px) {
    .items {
      margin: 1em 0;
      border-radius: 0;
      flex-wrap: wrap;
      gap: 7.7em 2em;
    }
  }
  @media (max-width: 640px) {
    .items {
      gap: 3em 1em;
      padding: 1em 2em 0em 2em;
      .item {
        width: 100%;
        height: auto;
        background: none;
        border-radius: 20px;
      }
    }
  }
  @media (max-width: 500px) {
    title {
      padding: 0 7%;
      h2 {
        font-size: 11vw;
      }
      p {
        text-align: center;
      }
    }
    .swiper {
      margin: 1em 2%;
      .slide {
        width: 100%;
        height: 55vh;
        img {
          height: 37vh;
        }
      }
    }
  }
`;
export default PerfumesSlider;

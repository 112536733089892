import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import bs1 from "../../assets/homeAssets/bs1.png";
import bs2 from "../../assets/homeAssets/bs2.png";
import bs3 from "../../assets/homeAssets/bs3.png";
import bs4 from "../../assets/homeAssets/bs4.png";
import bs5 from "../../assets/homeAssets/bs5.png";
import shanysLogo from "../../assets/homeAssets/shanysLogo.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const BestSellers = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h1>
        <span>{t("bs1")} </span> {t("bs2")}
      </h1>
      <div className="items">
        <div className="item">
          <img src={bs5} alt="" />
          <hr />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>PD231 Extrait de Parfum 50ml - Born in Roma</h2>
            <h3>50ml</h3>
            <p>
              The PD232 perfume, inspired by Valentino Donna by Valentino, is
              elegant and sophisticated. It opens with bergamot and plum,
              follows with a heart of rose and iris, and closes with a warm base
              of vanilla, patchouli and sandalwood. Perfect for special
              occasions and to express class
            </p>
            <Link to={"/produktet/PD231%20Extrait%20de%20Parfum%2050ml"}>
              <button>{t("seeMore")}</button>
            </Link>
          </div>
        </div>
        <div className="item">
          <img src={bs1} alt="" />
          <hr />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>Pure Active - Hyaluronic Acid Face Serum </h2>
            <h3>30ml</h3>
            <p>
              Hyaluronic acid serum is a moisturizing and lifting treatment.
              Formulated to act on three levels, it moisturizes and clefts the
              skin, counteracts wrinkles, stimulates cellular metabolism.
            </p>
            <Link
              to={
                "/produktet/Pure%20Active%20-%20Hyaluronic%20Acid%20Face%20Serum%2030ml"
              }
            >
              <button>{t("seeMore")}</button>
            </Link>
          </div>
        </div>
        <div className="item">
          <img src={bs2} alt="" />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>3 in 1 Eyebrow Intensifier 1 Light Brown</h2>
            <h3>15ml</h3>
            <p>
              The 3 in 1 eyebrow intensifier (gel, pencil and powder) is a
              versatile and innovative product designed to offer perfect
              eyebrows with ease. It offers a prolonged hold that keeps the
              eyebrows defined and in order all day long. Water resistant, ideal
              for resisting sweat, rain and humidity without smudging. Gel:
              Fixes and defines the eyebrows, giving a natural and well-groomed
              appearance. Pencil: Allows you to draw precise strokes and fill
              the sparse areas with control and ease. Powder: Adds volume and
              intensity, creating a soft and nuanced effect.
            </p>
            <Link
              to={
                "/produktet/3%20in%201%20Eyebrow%20Intensifier%201%20Light%20Brown"
              }
            >
              <button>{t("seeMore")}</button>
            </Link>
          </div>
        </div>
        <div className="item">
          <img src={bs3} alt="" />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>Vanilla eat fit</h2>
            {/* <h3>200ml</h3> */}
            <p>
              Meal replacement for weight control and loss. We recommend taking
              two scoops a day
            </p>
            <Link to={"/produktet/Vanilla%20eat%20fit"}>
              <button>{t("seeMore")}</button>
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h1 {
    font-family: "Alta";
    font-weight: 100;
    font-size: 6vw;
    span {
      color: var(--primaryColor);
    }
  }
  .items {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 7em;
    .item {
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
      position: sticky;
      top: 5em;
      background-color: #ffffff8b;
      backdrop-filter: blur(15px);
      -webkit-backdrop-filter: blur(15px);
      box-shadow: 0 0 12px 2px #d3d3d3;
      border-radius: 30px;
      padding-right: 10%;
      img {
        width: 45%;
        border-radius: 30px;
        max-height: 540px;
      }
      hr {
        height: 40vh;
      }
      div {
        img {
          width: 120px;
        }
        h2 {
          font-size: 3em;
          font-family: "Alta";
          font-weight: 100;
        }
        h3 {
          color: var(--primaryColor);
        }
        p {
          padding: 10px 0;
          color: gray;
        }
        button {
          background-color: var(--primaryColor);
          color: white;
          font-family: "Comfortaa";
          font-size: 18px;
          padding: 15px;
          border: none;
          cursor: pointer;
          transition: 0.4s;
          &:hover {
            background: #9b8c5d;
          }
        }
      }
    }
    :nth-child(2) {
      top: 7em;
    }
    :nth-child(3) {
      top: 9em;
    }
    :nth-child(4) {
      top: 11em;
    }
    :nth-child(5) {
      top: 13em;
    }
  }
  @media (max-width: 1200px) {
    .items {
      .item {
        div {
          h2 {
            font-size: 2em;
          }
          p {
            max-height: 100px;
            overflow: scroll;
          }
        }
      }
    }
  }
  @media (max-width: 850px) {
    h1 {
      font-size: 4em;
      text-align: center;
    }
    .items {
      width: 100%;
    }
  }
  @media (max-width: 650px) {
    .items {
      .item {
        flex-direction: column;
        height: 90vh;
        justify-content: center;
        align-items: center;
        padding-right: 0;
        gap: 10px;
        img {
          height: auto;
        }
        hr {
          height: 1px;
          width: 90%;
        }
        div {
          padding: 10px 20px;
          text-align: center;
          h2 {
            font-size: 1.7em;
          }
        }
      }
    }
  }
`;

export default BestSellers;

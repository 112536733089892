import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { MdKeyboardArrowDown } from "react-icons/md";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const FAQSection = () => {
  const [activeAnswer, setActiveAnswer] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("white"); // Default background
  const [isBlack, setIsBlack] = useState(false); // Flag to track if the background should stay black
  const ref = useRef(null);
  const { t } = useTranslation();

  // Using Intersection Observer to detect when the section is in view
  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isBlack) {
          setBackgroundColor("black"); // Change background to black when in view
          setIsBlack(true); // Lock the background color to black once it changes
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the section is in view
    )
  );

  useEffect(() => {
    const currentRef = ref.current;
    const currentObserver = observer.current;

    if (currentRef) {
      currentObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        currentObserver.unobserve(currentRef);
      }
    };
  }, [ref, isBlack]);

  return (
    <Container>
      <div className="title">
        <h1>FAQ</h1>
        <p>
          {t("faqDesc")} <br />
          {t("faqDesc2")}
        </p>
      </div>
      <div ref={ref} className="questions">
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: 0.3, type: "spring" }}
          className={activeAnswer === 1 ? "active" : "row"}
          onClick={() => setActiveAnswer(activeAnswer === 1 ? null : 1)}
        >
          <span>
            <h2>{t("faqQ1")}</h2>
            <MdKeyboardArrowDown className="icon" />
          </span>
          <p>{t("faqA1")}</p>
        </motion.div>
        <hr />
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: 0.5, type: "spring" }}
          className={activeAnswer === 3 ? "active" : "row"}
          onClick={() => setActiveAnswer(activeAnswer === 3 ? null : 3)}
        >
          <span>
            <h2>{t("faqQ4")}</h2>
            <MdKeyboardArrowDown className="icon" />
          </span>
          <p>{t("faqQ4")}</p>
        </motion.div>
        <hr />
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: 0.7, type: "spring" }}
          className={activeAnswer === 4 ? "active" : "row"}
          onClick={() => setActiveAnswer(activeAnswer === 4 ? null : 4)}
        >
          <span>
            <h2>{t("faqQ2")}</h2>
            <MdKeyboardArrowDown className="icon" />
          </span>
          <p>{t("faqA2")}</p>
        </motion.div>
        <hr />
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
          }}
          initial="hidden"
          animate="visible"
          transition={{ duration: 0.4, delay: 0.9, type: "spring" }}
          className={activeAnswer === 2 ? "active" : "row"}
          onClick={() => setActiveAnswer(activeAnswer === 2 ? null : 2)}
        >
          <span>
            <h2>{t("faqQ3")}</h2>
            <MdKeyboardArrowDown className="icon" />
          </span>
          <p>{t("faqA3")}</p>
        </motion.div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  background-color: #f9f5efce; /* Dynamic background */
  padding: 7em 4%;
  transition: background-color 0.3s ease; /* Smooth background transition */
  .title {
    text-align: center;
    h1 {
      font-weight: 100;
      font-size: 4.7em;
      font-family: "Alta", serif;
      color: BLACK;
    }
    p {
      width: 60%;
      margin: auto;
      margin-top: 1em;
      color: #3e3e3e;
      font-weight: 300;
    }
  }
  .questions {
    transition: 0.4s;
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 2em 0;
    align-items: center;
    hr {
      width: 80%;
      border: none;
      border-top: 1px solid #424242;
    }
    .row {
      transition: 0.4s;
      height: 30px;
      width: 80%;
      cursor: pointer;
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #2c2c2c;
        h2 {
          color: #242424;
          font-weight: 400;
          font-size: 18px;
        }
        .icon {
          font-size: 2em;
          color: var(--primaryColor);
        }
      }
      p {
        display: none;
      }
    }

    .active {
      transition: 0.4s;
      height: 50px;
      width: 80%;
      cursor: pointer;
      h2 {
        color: #0d0d0d;
      }
      p {
        transition: 0.4s;
        display: flex;
        color: #575757;
        font-weight: 300;
      }
      span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2 {
          font-weight: 500;
          font-size: 20px;
          transition: 0.4s;
          color: #2c2c2c;
        }
        .icon {
          transform: rotate(180deg);
          font-size: 2em;
          color: var(--primaryColor);
        }
      }
    }
  }
  @media (max-width: 1170px) {
    .questions {
      hr {
        width: 97%;
      }
      .row {
        width: 98%;
      }
      .active {
        width: 98%;
      }
    }
  }
  @media (max-width: 780px) {
    .questions {
      .row {
        h2 {
          font-size: 1.4em;
        }
      }
      .active {
        width: 98%;
        height: 70px;
        h2 {
          font-size: 1.4em;
        }
        p {
          line-height: 20px;
        }
      }
    }
  }
  @media (max-width: 680px) {
    .questions {
      .row {
        height: 40px;
        h2 {
          line-height: 24px;
        }
      }
    }
  }

  @media (max-width: 380px) {
    .questions {
      .row {
      }
      .active {
        height: 90px;
      }
    }
  }
`;

export default FAQSection;

import React, { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  setDoc,
  doc,
} from "firebase/firestore";
import styled from "styled-components";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const seasonsOptions = [
  { value: "Perfumes", label: "Perfumes" },
  { value: "Skincare", label: "Skincare" },
  { value: "Make Up", label: "Make Up" },
];

const ProductForm = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    document.title = "Admin | Beautyrocks Cosmetics";
  }, []);
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    description: "",
    price: "", // Added price here
    image: "",
    category: "",
    seasons: [],
    showInSlider: false,
  });
  const [imageFile, setImageFile] = useState(null);
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    const fetchBrands = async () => {
      const brandsRef = collection(db, "brands");
      const brandSnapshot = await getDocs(brandsRef);
      const brandList = brandSnapshot.docs.map((doc) => ({
        value: doc.id,
        label: doc.id,
      }));
      setBrandOptions(brandList);
    };
    fetchBrands();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    if (name === "image") {
      setImageFile(files[0]);
    } else if (type === "checkbox") {
      setProduct({ ...product, [name]: checked });
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  const handleSeasonsChange = (selectedOptions) => {
    if (selectedOptions.length <= 4) {
      setProduct({ ...product, seasons: selectedOptions });
    }
  };

  const handleBrandChange = async (selectedOption) => {
    const brandValue = selectedOption ? selectedOption.value : "";

    setProduct({
      ...product,
      brand: brandValue,
    });

    if (selectedOption && selectedOption.__isNew__) {
      try {
        const brandRef = doc(db, "brands", brandValue);
        await setDoc(brandRef, { name: brandValue }, { merge: true });

        const updatedBrands = await getDocs(collection(db, "brands"));
        const updatedBrandList = updatedBrands.docs.map((doc) => ({
          value: doc.id,
          label: doc.id,
        }));
        setBrandOptions(updatedBrandList);
      } catch (error) {
        console.error("Error adding brand: ", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageUrl = "";
      if (imageFile) {
        const imageRef = ref(storage, `images/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        imageUrl = await getDownloadURL(imageRef);
      }

      const productRef = collection(db, "products");
      await addDoc(productRef, {
        ...product,
        image: imageUrl,
        price: parseFloat(product.price), // Include price when submitting
        seasons: product.seasons.map((season) => season.value),
      });

      setProduct({
        name: "",
        brand: "",
        description: "",
        price: "", // Reset price
        image: "",
        category: "",
        seasons: [],
        showInSlider: false,
      });
      setImageFile(null);
      alert("Product added successfully!");
    } catch (error) {
      console.error("Error adding product: ", error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#303030",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#303030",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "grey" : "#303030",
      color: "white",
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: "grey",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: "white",
    }),
  };

  return (
    <Container>
      <h1>Regjistro Produkt</h1>
      <form onSubmit={handleSubmit}>
        <span>
          <input
            name="name"
            value={product.name}
            onChange={handleChange}
            placeholder="Name"
            required
          />
          <input
            name="price"
            value={product.price} // Added price input
            onChange={handleChange}
            placeholder="Price"
            type="number"
            step="0.01" // Allows decimal values for price
            required
          />
        </span>
        <textarea
          name="description"
          value={product.description}
          onChange={handleChange}
          placeholder="Description"
          required
        />
        <span>
          <input name="image" type="file" onChange={handleChange} required />
          <Select
            className="select"
            isMulti
            options={seasonsOptions}
            value={product.seasons}
            onChange={handleSeasonsChange}
            closeMenuOnSelect={false}
            isOptionDisabled={() => product.seasons.length >= 4}
            styles={customStyles}
          />
        </span>
        <button type="submit">Add Product</button>
      </form>
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  text-align: center;
  h1 {
    font-family: "Comfortaa";
    font-size: 2.2em;
    font-weight: 200;
    margin-bottom: 10px;
  }
  form {
    margin: auto;
    background-color: #303030;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 40px 30px;
    width: 700px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    span {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 1em;
      input,
      textarea,
      select {
        width: 49%;
      }
    }
    input,
    textarea,
    select {
      width: 100%;
      background: none;
      color: white;
      font-size: 18px;
      font-family: "Comfortaa";
      border: 1px solid white;
      border-radius: 20px;
      padding: 14px 14px;
      outline: none;
    }
    option {
      color: black;
      padding: 7px;
    }
    button {
      background: white;
      font-family: "Comfortaa", sans-serif;
      padding: 12px 20px;
      border: none;
      border-radius: 30px;
      font-size: 19px;
      margin-top: 1em;
      cursor: pointer;
      transition: 0.4s;
      &:hover {
        background: var(--primaryColor);
        color: white;
      }
    }
  }
  @media (max-width: 960px) {
    form {
      width: 98%;
    }
  }
  @media (max-width: 550px) {
    padding: 6em 0%;
    form {
      width: 100%;
      padding: 30px 20px;
      span {
        flex-direction: column;
        input,
        textarea,
        select {
          width: 100%;
        }
      }
    }
  }
`;

export default ProductForm;

import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Words = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);

  const resources = [
    { title: t("txa1") },
    { title: t("txa2") },
    { title: t("txa3") },
    { title: t("txa4") },
  ];

  useEffect(() => {
    const section = sectionRef.current;

    gsap.to(section.querySelectorAll(".text-reveal"), {
      scrollTrigger: {
        trigger: section,
        start: "top 80%", // Start the animation when the top of the section reaches 80% of the viewport height
        end: "bottom 20%", // End when the bottom of the section reaches 20% of the viewport height
        scrub: 1,
        toggleActions: "restart none none reverse",
      },
      opacity: 1,
      x: 0,
      ease: "power2.out",
      stagger: 0.2,
    });
  }, []);

  return (
    <>
      <ResourcesContainer ref={sectionRef}>
        {resources.map((resource, index) => (
          <ResourceBlock key={index} className="text-reveal">
            <BigText rel="noopener noreferrer">{resource.title}</BigText>
          </ResourceBlock>
        ))}
      </ResourcesContainer>
    </>
  );
};

export default Words;

// Styled Components
const ResourcesContainer = styled.section`
  height: 210vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  padding-top: 10em;
  color: #202020;
  position: relative;
  h1 {
    top: 5%;
    text-align: center;
    position: absolute;
    font-size: 4vw;
    margin: auto;
    width: 100%;
    font-weight: 300;
    background: linear-gradient(90deg, orange, purple);
    background-clip: text;
    color: #202020;
  }
`;

const ResourceBlock = styled.div`
  opacity: 0;
  transform: translateX(-100%);
`;

const BigText = styled.a`
  font-size: 6rem;
  font-weight: bold;
  color: #202020;
  -webkit-text-stroke: 2px #fff;
  position: relative;
  text-decoration: none;
  display: inline-block;
  transition: all 0.5s ease;

  @media (max-width: 600px) {
    font-size: 15vw;
  }

  &:hover {
    color: var(--primaryColor);
    transform: rotateY(15deg) scale(1.05);
  }
`;

// Text2.js
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";

gsap.registerPlugin(ScrollTrigger);

const Text2 = () => {
  const introRef = useRef(null);
  const secondSectionRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Intro text animation
    const introText = introRef.current.querySelectorAll("span");

    gsap.from(introText, {
      scrollTrigger: {
        trigger: introRef.current,
        start: "top 80%",
      },
      delay: 0.7,
      opacity: 0,
      y: 50,
      stagger: 0.3,
      duration: 1,
      ease: "power2.out",
    });

    // Background color change for both sections
    gsap.to("body", {
      // backgroundColor: "#0e0e0e", // Set background to black
      scrollTrigger: {
        trigger: introRef.current, // Start the background change when the intro section is in view
        start: "top 50%", // Start as soon as half the intro section is visible
        endTrigger: secondSectionRef.current, // Keep it black throughout both sections
        end: "bottom bottom", // Until the end of the second section
        toggleActions: "play none none reverse", // Reverse when scrolling back up
      },
      duration: 0.5, // Smooth transition
    });
  }, []);

  return (
    <>
      <Section ref={introRef}>
        <IntroText>
          <span>{t("text1")}</span>
          <span>{t("text2")}</span>
          <span>{t("text3")}</span>
          <span>{t("text4")}</span>
        </IntroText>
      </Section>
    </>
  );
};

export default Text2;

// Styled Components
const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 6vh;
`;

const IntroText = styled.h2`
  font-size: 4vw;
  text-align: center;
  color: #2a2a2a; // White text to contrast with black background
  span {
    display: block;
    margin: 1rem 0;
  }
`;

const SecondSection = styled.section`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; // Keep text white for contrast with the black background
  background-color: transparent; // Keep transparent to ensure the body background remains black
`;
